import AboutPic from "../img/about1.png";
import Mission from "../img/about2.png";
import Vision from "../img/about3.png";
import Security from "../img/about4.png";
import Infra from "../img/about5.png";
import Practices from "../img/about6.png";
import ShowMoreText from "react-show-more-text";
import Breadcrumbs from "../components/common/Breadcrumbs/breadcrumbs";
import SubHeading1 from "../components/common/SubHeading1/subHeading1";
import Footer from "../components/layout/footer/footer";


const LDPCA = () => {


    return (
        <>
            <div className="banner">
                <div className="bannerContent">

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h1>Welcome to the Diasporan Initiative</h1>
                        <p style={{ marginTop: '2%' }}>Strengthening bonds between African diasporans and their home continent.</p>
                    </div>
                </div>
            </div>


            <div className="aboutusContainer">
                <div className="aboutusContent">
                    <Breadcrumbs text="Diasporan Initiative" />
                    <div>
                        <div className="desc2 reverse">
                            <div className="imgContainer">
                                <img src={AboutPic} />
                            </div>

                            <div className="textContainer">
                                <SubHeading1 text="What is DI" />

                                <ShowMoreText
                                    className="showmoreContainer"
                                    lines={6}
                                    anchorClass="show-more-less-clickable"
                                >
                                    <p>
                                        The Diasporan Connection Initiative is a visionary project aimed at strengthening the bonds between African diasporans and their home continent. Our mission is to bridge the gap between diasporans living abroad and corporate organizations in Africa, facilitating seamless transitions for those returning or relocating to their roots. Through a dynamic blend of professional engagement and cultural collaboration, we're shaping a future where skills, expertise, and opportunities flow freely across borders.
                                    </p>




                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="desc2">
                            <div className="imgContainer">
                                <img src={Mission} />
                            </div>

                            <div className="textContainer">
                                <SubHeading1 text="Our Vision" />

                                <ShowMoreText
                                    className="showmoreContainer"
                                    lines={6}
                                    anchorClass="show-more-less-clickable"
                                >
                                    <p>
                                        Imagine a world where the talents of African diasporans contribute to the growth and prosperity of their home countries. Our vision is to facilitate a two-way exchange that empowers diasporan professionals to share their expertise, create lasting impacts, and be recognized as invaluable contributors to Africa's success story.
                                    </p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="desc2 reverse">
                            <div className="imgContainer">
                                <img src={Vision} />
                            </div>

                            <div className="textContainer">
                                <SubHeading1 text="How It Works" />

                                <ShowMoreText
                                    className="showmoreContainer"
                                    lines={6}
                                    anchorClass="show-more-less-clickable"
                                >
                                    <p>
                                        The Diasporan Connection Initiative operates on a simple yet powerful principle. We encourage professionals from the diaspora to engage with African corporate organizations during their visits to the continent. Through short-term volunteer opportunities, workshops, and collaborations, diasporans gain local work experience, create meaningful connections, and showcase their skills to potential employers.

                                    </p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="desc2">
                            <div className="imgContainer">
                                <img src={Mission} />
                            </div>

                            <div className="textContainer">
                                <SubHeading1 text="Mutual Learning" />

                                <ShowMoreText
                                    className="showmoreContainer"
                                    lines={6}
                                    anchorClass="show-more-less-clickable"
                                >
                                    <p>
                                        Our initiative fosters knowledge exchange between diasporan professionals and African organizations, creating an environment of continuous learning and growth.
                                    </p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="desc2 reverse">
                            <div className="imgContainer">
                                <img src={Vision} />
                            </div>

                            <div className="textContainer">
                                <SubHeading1 text="Skill Utilization" />

                                <ShowMoreText
                                    className="showmoreContainer"
                                    lines={6}
                                    anchorClass="show-more-less-clickable"
                                >
                                    <p>
                                        By participating in real projects and contributing their expertise, diasporans develop a portfolio of work that demonstrates their capabilities.
                                    </p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="desc2">
                            <div className="imgContainer">
                                <img src={Mission} />
                            </div>

                            <div className="textContainer">
                                <SubHeading1 text="Seamless Transitions" />

                                <ShowMoreText
                                    className="showmoreContainer"
                                    lines={6}
                                    anchorClass="show-more-less-clickable"
                                >
                                    <p>
                                        As diasporans decide to relocate or return to Africa, their established working history helps them stand out to potential employers, ensuring a smoother transition into the workforce.
                                    </p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="desc2 reverse">
                            <div className="imgContainer">
                                <img src={Vision} />
                            </div>

                            <div className="textContainer">
                                <SubHeading1 text="Cultural Exchange" />

                                <ShowMoreText
                                    className="showmoreContainer"
                                    lines={6}
                                    anchorClass="show-more-less-clickable"
                                >
                                    <p>
                                        Beyond professional engagement, our initiative encourages cultural exchange, strengthening the diaspora's ties to their heritage.                  </p>
                                </ShowMoreText>
                            </div>
                        </div>

                        <div className="desc2">
                            <div className="imgContainer">
                                <img src={Mission} />
                            </div>

                            <div className="textContainer">
                                <SubHeading1 text="Membership and Partnerships" />

                                <ShowMoreText
                                    className="showmoreContainer"
                                    lines={6}
                                    anchorClass="show-more-less-clickable"
                                >
                                    <p>
                                        We welcome diasporan professionals from various industries to join our initiative. By becoming a member, you gain access to exclusive opportunities, networking events, and resources designed to support your journey. Additionally, we partner with forward-thinking African organizations that are eager to harness the skills of diasporan professionals to drive innovation and growth.                  </p>
                                </ShowMoreText>
                            </div>


                        </div>






                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
};

export default LDPCA;
